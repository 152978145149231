body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.user-select-none {
  user-select: none;
}

.autocomplete-options {
  position: absolute;
  width: 95%;
  z-index: 100;
}

.autocomplete-container {
  position: relative;
}

.autocomplete-button {
  position: absolute;
  right: 0px;
  top: 0;
}