.documents-container {
    min-height: 80vh;
    /* height: 100%; */
}

.document-container {
    /* height:497mm; */
    min-height: 287mm;
    width: 200mm;
    /* width:210mm; */
    /* border: 1px solid black; */
    margin: 20px;
}

.document-list {
    width: 100%;
}

p {
    font-size: 0.8rem;
    margin: 0px;
}

div {
    font-size: 0.8rem;
}

.small-fontSize {
    font-size: 0.5rem;
}

.big-fontSize {
    font-size: 1.3rem;
    margin: 0
}

.document-border {
    margin: 1px;
    border: 1px solid black;
}

.document-header {
    height: 30mm;
    width: 100%;
}

.document-header div {
    margin: 0px;
    padding: 1px;
    width: 100%;
}

.original-container div {
    min-width: 100%;
    height: 100%;
}

.document-address-box {
    height: 30mm;
    width: 50%;
    margin: 1px;
    border: 1px solid black;
    padding-left: 3px;
}

.document-header-name {
    border: 1px solid black;
    background-color: gray
}

.document-address-row p {
    margin: 0px 3px 0px 3px
}

.document-short-two-values {
    height: 12mm;
    padding: 1px;
    padding-bottom: 3px;
    padding-left: 3px;
}

.product-table {
    margin: 1px;
    font-size: 10px;
}

.product-table-cell {
    border: 1px solid black;
}

.test {
    background-color: gray !important;
}

.document-footer {
    height: 40mm;
    width: 100%;
}

.document-footer-box {
    width: 40%;
    border: 1px solid black;
    margin: 20px 1px 20px 1px;
}

.document-import-errors {
    border: 1px solid black;
    margin: 8px;
    padding: 20px;
}

.document-import-errors-products {
    padding-bottom: 8px;
}

.document-list-status {
    height: 16px;
    width: 16px;
    border-radius: 100px;
    /* border: 1px solid red; */
}

.document-status-1 {
    background-color: #dd2c00;
}

.document-status-2 {
    background-color: #ffeb3b;
}

.document-status-3 {
    background-color: #4caf50;
}

.document-single-details-container {
    display: flex;
    flex-wrap: wrap;
}

.document-single-details-container div {
    /* flex-grow: 1; */
    width: 33%;
}

.document-details-address-box {}

.document-details-address-box-container {
    /* margin: 8px 0px 8px 0px; */
    display: flex;
    flex-wrap: wrap;
}

.document-details-address-box-container div {
    /* margin: 8px 0px 8px 0px; */
    width: 33%;
}

.completion-disabled {
    background-color: #ff7878;
}

.completion-disabled:hover {
    background-color: #a56b6b;
}

.active-document {
    padding-left: 10px;
    border-left: 12px solid #3775ff;
}

.inspection-status-1 {
    background-color: #667292;
}

.inspection-status-2 {
    background-color: #87bdd8;
}

.inspection-status-3 {
    background-color: #cfe0e8;
}