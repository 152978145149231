.list-record {
    padding: 15px 20px;
    margin: auto;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    line-height: 1.42857143;
}

.list-record-cursor {
    cursor: pointer;
}

.list-card {
    margin-bottom: 16px
}

.details {
    display: flex;
}

.single-detail {
    flex: 1;
    margin: auto
}

.single-detail-button {
    flex: 0;
    margin: auto
}

.position-list-record-print-button {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.position-list-record-info {
    display: flex;
}

.position-list-record-info .info {
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 30px;
    font-size: 20px;
}

.position-list-record-info .barcode {
    flex: 2;
}

.position-list-record-print-button .btn {
    width: 110px;
    cursor: pointer;
}

.single-detail-button .btn {
    cursor: pointer;
}

@media screen {
    .position-code {
        font-size: 20px;
    }
    .barcode-to-print {
        width: 350px
    }
}

@media print {
    .container-to-print {
        display: flex;
    }
    .position-code {
        font-size: 120px;
        margin: auto;
        transform: scale(0.9, 2.5);
    }
    .barcode-to-print {
        width: 570px;
        margin-top: 120px;
        padding-right: 20px;
    }
}

.container .add-form {
    margin-top: 35px;
}

.list {
    list-style: none;
    padding: 0;
}

.text-align-center {
    text-align: center;
}

.text-align-end {
    text-align: end;
}